import '../styles/css/listing.css';
import { Checkbox } from '@mui/material';
import { useEffect, useCallback } from 'react';
import axios from 'axios';
import PropertyModel from '../data/models/property';
import React from 'react';
import { useTranslation } from "react-i18next";
import Config from '../config';

export default function FeaturesChecks({ 
  features, 
  filters, 
  setPageCount, 
  itemsPerPage, 
  setTotalProperties, 
  setCurrentIndex, 
  setFilters, 
  setProperties, 
  setIsLoading 
}) {
  const { t } = useTranslation();

  const searchProperties = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await axios.post(
        `${Config.apiUrl}properties/search`, 
        filters, 
        { headers: { "content-type": "application/json" } }
      );

      const responseData = response.data;
      if (!responseData || !responseData.data) {
        console.error("Invalid data received:", responseData);
        return;
      }

      const data = responseData.data;
      const properties = data.properties || [];
      const result = properties.map(p => new PropertyModel(
        p.id, p.name, p.price, p.rooms, p.m2, p.description, 
        p.isFavourited, p.features, p.images
      ));

      const total = properties.length > 0 ? properties[0].total : 0;
      setTotalProperties(total);
      setPageCount(Math.ceil(total / itemsPerPage));
      setProperties(result);

      localStorage.setItem("filters", JSON.stringify(filters));
    } catch (error) {
      console.error('Error searching properties:', error);
    } finally {
      setIsLoading(false);
    }
  }, [filters, setPageCount, itemsPerPage, setTotalProperties, setProperties, setIsLoading]);

  // useEffect(() => {
  //   searchProperties();
  // }, [searchProperties]);

  const onChangeFeatures = useCallback((id, checked) => {
    console.log("Change feautures");
    setCurrentIndex(1);

    debugger
    const newFeatures = checked ? filters.Features.concat(id) : filters.Features.filter(featureId => featureId !== id);
    filters.From = 0
    filters.Features = newFeatures;

    setFilters(filters);
    setIsLoading(true);
    const headers = {
      "content-type": "application/json",
    };

    axios.post(Config.apiUrl + 'properties/search', filters, { headers })
      .then(response => {
        var propes = response.data.data.properties;
        var result = propes.map(p => new PropertyModel(p.id, p.name, p.price, p.rooms, p.m2, p.description, p.isFavourited, p.features, p.images));
      
        setIsLoading(false);

        var total = 0;
        if(propes.length > 0){
          total = response.data.data.properties[0].total;
        }
        setTotalProperties(total);
        setPageCount(Math.ceil(total / itemsPerPage));
        setProperties(result);

        var jsonFilters = JSON.stringify(filters);
        localStorage.setItem("filters", jsonFilters);
      });
  }, [setCurrentIndex, setFilters]);

  if (features.length === 0) {
    return null;
  }
  

  return (
    <div className="input-group mb-3">
      <label>{t('features')}:</label>
      <div className='row'>
        {features.map(f => (
          <div key={f.id} className="form-check" style={{ paddingLeft: 0 }}>
            <Checkbox
              onChange={e => onChangeFeatures(f.id, e.target.checked)}
              checked={filters.Features.includes(f.id)}
              sx={{
                color: "#F65C01",
                '&.Mui-checked': {
                  color: "#F65C01",
                },
              }}
            />
            <label className="form-check-label">
              {f.name}
            </label>
          </div>
        ))}
      </div>
    </div>
  );
}